var Handlebars = require("../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <div class=\"slide\">\n                <div class=\"slide-image-wrap\">\n                    <img src=\""
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"enlarged") : depth0), depth0))
    + "\" alt=\"\">\n                </div>\n            </div>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <div class=\"slide\">\n                <img src=\""
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"small") : depth0), depth0))
    + "\"\n                     alt=\"\">\n            </div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"modal__gallery\">\n    <div id=\"ref-"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"c") : depth0)) != null ? lookupProperty(stack1,"ID") : stack1), depth0))
    + "-"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"c") : depth0)) != null ? lookupProperty(stack1,"index") : stack1), depth0))
    + "-slider-enl\" class=\"slick-slider slider--three\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias3,((stack1 = (depth0 != null ? lookupProperty(depth0,"c") : depth0)) != null ? lookupProperty(stack1,"images") : stack1),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":3,"column":8},"end":{"line":9,"column":17}}})) != null ? stack1 : "")
    + "    </div>\n    <div id=\"ref-"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"c") : depth0)) != null ? lookupProperty(stack1,"ID") : stack1), depth0))
    + "-"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"c") : depth0)) != null ? lookupProperty(stack1,"index") : stack1), depth0))
    + "-enl-nav\" class=\"slick-slider slider--four\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias3,((stack1 = (depth0 != null ? lookupProperty(depth0,"c") : depth0)) != null ? lookupProperty(stack1,"images") : stack1),{"name":"each","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":12,"column":8},"end":{"line":17,"column":17}}})) != null ? stack1 : "")
    + "    </div>\n\n    <button class=\"close-button\" data-close-gallery>< zurück</button>\n\n</div>";
},"useData":true});