let icon = require('../../templates/bookmark/bookmark.hbs');
import BookmarkDialog from "./BookmarkDialog";
import BookmarkCollection from "./BookmarkCollection";
import store from 'store'

export default Backbone.View.extend({
  tagName: 'div',
  className: 'ref-bookmark-manager',
  initialize: function (options) {
    this.controller = options.controller;
    this.$wrap = options.$wrap;
    this.Bookmarks = new BookmarkCollection();
    this.Dialog = new BookmarkDialog({manager: this, collection: this.Bookmarks});
    this.render();
    this.loadBookmarksFromStorage();
    this.Bookmarks.on('add', () => this.renderIcon());
    this.Bookmarks.on('remove', () => this.renderIcon());
    this.$wrap.on('click', () => {this.openDialog()} )
  },
  isBookmarked: function (id) {
    let check = _.filter(this.Bookmarks.getItems(), (i) => {
      return i.ID === id;
    });
    return check.length > 0;
  },
  loadBookmarksFromStorage: function () {
    let marks = store.get('bookmarks')
    if (typeof marks !== "undefined") {
      this.Bookmarks.add(marks);
    }
    this.renderIcon();

  },
  addItem: function (item) {
    this.Bookmarks.add(item.model.toJSON(), {merge: true});
    this.Bookmarks.sync();
  },
  removeItem: function (item) {
    this.Bookmarks.remove(item.model.toJSON(), {merge: true});
    this.Bookmarks.sync();
  },
  openDialog: function () {
    this.Dialog.show();
  },
  renderIcon: function () {
    let count = this.Bookmarks.getItems().length;
    let larger = '';
    if (count > 9){
      larger = 'wide-count'
    }
    if (count > 99){
      larger = 'wider-count'
    }
    this.$wrap.empty().append(icon({length: count, countclass: larger }));
  },
  render: function () {
    jQuery('body').append(this.$el);
  }
})