let baseTpl = require('../../templates/bookmark/dialog.hbs');
import sortable from 'html5sortable/dist/html5sortable.es'
import tippy from 'tippy.js/dist/tippy.esm'
import $ from 'jquery'
import ClipboardJS from "clipboard/dist/clipboard";

export default Backbone.View.extend({
  className: 'bookmark-manager',
  tagName: 'div',
  initialize: function (options) {
    this.manager = options.manager;
    this.collection = options.collection;
    this.isOpen = false;
    this.render();
    this.setup();
  },
  events: {
    'click [data-create-multi-pdf]': 'triggerPdf',
    'click [data-dialog-close]': 'hide',
    'click [data-share-list]': 'showShare',
    'click [data-bookmark-remove]': 'removeItem'
  },
  removeItem: function (e) {
    let $item = jQuery(e.currentTarget);
    let id = $item.data('ref-id');
    this.collection.remove(id);
    this.collection.sync();

  },
  triggerPdf: function () {
    let items = this.$('.bk--remove');
    let ordered = _.map(items, (i) => {
      return i.dataset.refId;
    });
    let url = '/?createpdf&multipdf=' + ordered.join(',');
    window.open(url, '_blank');
  },
  toggleUrl: async function () {
    let fdata = await this.getShareUrl();
    let res = window.tpdata.homeurl + '/referenzen/' + fdata;
    this.$('input').val(res);

  },
  showShare: function () {
    this.$('.bk-share-link-wrap').slideToggle();
    this.toggleUrl();
  },
  getShareUrl: async function () {
    let items = this.$('.bk--remove');
    let ordered = _.map(items, (i) => {
      return i.dataset.refId;
    });
    let url = window.tpdata.homeurl + '/referenzen/merkliste/?referenzen=' + ordered.join(',');
    let fdata = await jQuery.ajax(window.tpdata.endpoints.shorturl, {
      method: 'GET',
      data: {url: url}
    });
    return fdata;
  },
  loadList: function () {
    let items = this.$('.bk--remove');
    let ordered = _.map(items, (i) => {
      return i.dataset.refId;
    });
    let url = '/referenzen/merkliste/?referenzen=' + ordered.join(',');
    window.open(url, '_blank');
  },
  setup: function () {
    this.collection.on('add', (e) => {
      this.renderItems();
      this.toggleUrl();
    })
    this.collection.on('remove', (e) => {
      this.renderItems();
      this.toggleUrl();
    })

    const instance = tippy(document.querySelector('.bk-copy'));
    let clipboard = new ClipboardJS('.bk-copy');
    clipboard.on('success', function (e) {
      e.clearSelection();
      let tip = tippy('.bk-copy', {
        theme: 'dskbig'
      });
      tip[0].setContent('Adresse kopiert');
      tip[0].show();
      setTimeout(function () {
        tip[0].destroy();
      }, 2000)
    });
  },
  render: function () {
    this.$el.empty().append(baseTpl({options: window.tpdata.options}))
    jQuery('body').append(this.$el);
  },
  renderItems: function () {
    this.$el.empty().append(baseTpl({items: this.collection.getItems(), options: window.tpdata.options}))
    sortable('.bk-sortable', {
      placeholderClass: 'bk--placeholder'
    });
    this.$('.bk-sortable').on('sortupdate', () => {
      this.toggleUrl();
    })
  },
  show: function () {
    this.$el.addClass('show-dialog');
    if (L.Browser.mobile !== true) {
      let tips = tippy('[data-tippy-content]', {
        theme: 'dskbig'
      });
    }
    let modalContent = this.$('.scroll-container')
    let styles = getComputedStyle(modalContent[0]);

    if (styles['-webkit-overflow-scrolling'] !== 'touch') {
      modalContent.overlayScrollbars({
        autoUpdate: true
      });
    }

    this.isOpen = true;
    _.defer(() => {
      $('body').on('click.bkm', (e) => {
        let container = this.$el;
        if ($(e.target).hasClass('bk--remove')) return;
        if (!container.is(e.target) && container.has(e.target).length === 0) {
          this.hide();
          $('body').off('click.bkm');
        }
      })
    })
  },
  hide: function () {
    this.$el.removeClass('show-dialog');
    this.isOpen = false;

  }
});