import FilterV2 from './filter/Filter';
import ProjectsList from './filter/ProjectsList';

import './plugins';




jQuery(document).ready(function ($) {


  // replaceTel();

  $('[data-filter-ext]').each(function (index, el) {
    var FV2 = new FilterV2({
      el: el
    })
    FV2.render();
  });

  $('[data-projects-list]').each(function (index, el) {
    var PL = new ProjectsList({
      el: el,
      controller: new FilterV2({
        el: el
      }),
      items: []
    })
    PL.render();
  });

  $('body').on('click', '[data-gallery]', function (e) {
    e.preventDefault();
    var $this = $(this);
    var id = $this.data('gallery');
    var pswpElement = $('.pswp')[0];
    var options = {
      galleryUID: id,
      bgOpacity: 0.85,
      index: 0,
      shareEl: false
    };
    var referenceItems = window[id];
    var gallery = new PhotoSwipe(pswpElement, PhotoSwipeUI_Default, referenceItems, options);
    gallery.init();

  });


});


