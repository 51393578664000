var Handlebars = require("../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <a class=\"btn\" target=\"_blank\" href=\""
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"c") : depth0)) != null ? lookupProperty(stack1,"pin") : stack1)) != null ? lookupProperty(stack1,"url") : stack1), depth0))
    + "\">"
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"c") : depth0)) != null ? lookupProperty(stack1,"pin") : stack1)) != null ? lookupProperty(stack1,"buttontext") : stack1), depth0))
    + "</a>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"grid-container\">\n  <div class=\"grid-x grid-margin-x\">\n    <div class=\"cell\">\n      <div class=\"popup-wrap\">\n        <button class=\"close-button\" data-close aria-label=\"Close modal\" type=\"button\">\n          <span aria-hidden=\"true\">&times;</span>\n        </button>\n        <h6 class=\"popup-hl hl hl--six\">\n          "
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"c") : depth0)) != null ? lookupProperty(stack1,"pin") : stack1)) != null ? lookupProperty(stack1,"name") : stack1), depth0))
    + " / <span>"
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"c") : depth0)) != null ? lookupProperty(stack1,"pin") : stack1)) != null ? lookupProperty(stack1,"client") : stack1), depth0))
    + "</span>\n\n        </h6>\n        <div class=\"popup-image\">\n          <img src=\""
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"c") : depth0)) != null ? lookupProperty(stack1,"pin") : stack1)) != null ? lookupProperty(stack1,"image") : stack1)) != null ? lookupProperty(stack1,"url") : stack1), depth0))
    + "\">\n        </div>\n        <div class=\"popup-content\">\n          <div class=\"popup-subtitle\">"
    + ((stack1 = alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"c") : depth0)) != null ? lookupProperty(stack1,"pin") : stack1)) != null ? lookupProperty(stack1,"description") : stack1), depth0)) != null ? stack1 : "")
    + "</div>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"c") : depth0)) != null ? lookupProperty(stack1,"pin") : stack1)) != null ? lookupProperty(stack1,"linked") : stack1),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":17,"column":10},"end":{"line":19,"column":17}}})) != null ? stack1 : "")
    + "        </div>\n      </div>\n    </div>\n  </div>\n</div>\n\n";
},"useData":true});