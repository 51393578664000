let tileWrap = require('../../templates/stages/tiles-wrap.hbs');
let tileItem = require('../../templates/stages/project-item-wrap.hbs');
import ModalHandler from "../modals/ModalHandler";
import EntryView from "../modals/EntryView";
import TilePagination from "./TilePagination";
export default Backbone.View.extend({
  active: false,
  hasMore: true,
  params: {
    number: 20,
    offset: 0
  },
  events: {
    'click [data-lm-trigger]': 'loadEntries'
  },
  initialize: function (options) {
    this.controller = options.controller;
    this.ModalHandler = new ModalHandler({controller: this.controller});
  },
  render: function () {
    this.$el.empty().append(tileWrap());
    this.setupElements();
  },
  setupElements: function () {
    this.$lmcontainer = this.$('[data-lm-wrap]');
    this.$tilesWrap = this.$('[data-tiles-wrap]');
    this.TilePagination = new TilePagination({el: this.$('[data-lm-trigger]'), controller: this.controller, stage: this})
    // this.$loadMore = this.$('[data-lm-trigger]');
  },
  loadEntries: async function () {
    let markers = await this.controller.getMarkers(this.params);
    this.setEntries(markers.entries)
    this.params.offset = markers.pagination.offset;
    this.hasMore = markers.pagination.hasMore;
    this.TilePagination.setPagination(markers.pagination);
    // this.hasMore ? this.$loadMore.show() : this.$loadMore.hide();
  },
  setActive: function () {
    this.active = true;
    this.loadEntries();
    this.controller.on('filter.config.changed', (e) => {
      this.params.offset = 0;
      this.loadEntries()
    },this)
    this.render();
  },
  setInactive: function () {
    this.$el.empty();
    this.controller.off('filter.config.changed',null,this);
  },
  setEntries: function (entries) {
    let views = _.map(entries, (e) => {
      return new EntryView({model: new Backbone.Model(e), ModalHandler: this.ModalHandler})
    });
    this.renderEntries(views)
  },
  renderEntries: function (entries) {
    this.$tilesWrap.empty();
    _.each(entries, (e) => {
      this.$tilesWrap.append(e.render());
    })

  }
})