import ModalHandler from "../modals/ModalHandler";
import EntryView from "../modals/EntryView";

let mapWrap = require('../../templates/stages/map-wrap.hbs');
let markerPopup = require('../../templates/stages/map-popup.hbs');
let geoJson = require('./germany.json');
export default Backbone.View.extend({
  active: false,
  Map: null,
  markers: null,
  params: {
    number: 500,
    offset: 0
  },
  userMarker: null,
  initialize: function (options) {
    this.controller = options.controller;
    this.ModalHandler = new ModalHandler({controller: this.controller});
    this.controller.on('user.location.marker', (coords) => {
      this.addUserMarker(coords);
    })
  },
  render: function () {
    let that = this;
    if (!this.active) {
      return;
    }

    // if (this.controller.initialized !== true) {
    //   this.controller.once('filter.data.loaded', function () {
    //     _.defer(function () {
    //       that.render();
    // });
    // },this)
    // return;
    // }
    if (!this.Map) {
      _.defer(() => {
        this.setupMap()
      });
    } else {
      this.Map.invalidateSize();
    }
  },
  setupMap: function () {
    let that = this;
    if (this.$('#filter-map').length > 0) {
      this.$('#filter-map').remove();
    }


    this.$el.append(mapWrap());
    if (this.Map) this.Map.remove();
    var southWest = L.latLng(47.249406957888446, 6.4703125),
      northEast = L.latLng(55.178867663281984, 15.3369140625),
      bounds = L.latLngBounds(southWest, northEast);
    this.bounds = bounds;

    // Kartenobjekt erstellen, mit Optionen zum Zentrieren und Zoom Level setzen
    let map = L.map('filter-map', {
      // maxBounds: bounds,
      zoom: 7,
      minZoom: 5,
      zoomAnimation: false,
      // dragging: !L.Browser.mobile,
      tap: !L.Browser.mobile
    });
    // map.fitBounds(bounds);


    // ESRI Base-Layer
    // this.Esri_WorldGrayCanvas = L.tileLayer('https://server.arcgisonline.com/ArcGIS/rest/services/Canvas/World_Light_Gray_Base/MapServer/tile/{z}/{y}/{x}', {
    // this.Esri_WorldGrayCanvas = L.tileLayer('https://tiles.stadiamaps.com/tiles/alidade_smooth/{z}/{x}/{y}{r}.png', {
    //   attribution: '',
    //   maxZoom: 20,
    //   access_token: '8ec4ea83-d617-44af-8dd4-4e765e19dab3'
    // }).addTo(map);
    L.tileLayer.provider('OpenStreetMap.DE', {
      apiKey: '8ec4ea83-d617-44af-8dd4-4e765e19dab3',
      accessToken: '8ec4ea83-d617-44af-8dd4-4e765e19dab3'
    }).addTo(map);

    //
    var geojson = L.geoJson(geoJson, {
      invert: true,
      style: {
        "color": "#999",
        "fillColor": "#fff",
        "fillOpacity": 0.5,
        "weight": 0.1,
        "opacity": 1
      }
    }).addTo(map)
    this.Map = map;
    setTimeout(() => {
      map.invalidateSize();
      that.getMarkers();
    }, 1000)

    this.bounds = geojson.getBounds();
    map.fitBounds(this.bounds)
    map.setZoom(6.5);
  },
  setActive: function () {
    this.active = true;
    this.controller.on('filter.markers.loaded', (e) => {
      this.setMarkers(e)
    }, this)
    this.controller.on('filter.config.changed', (e) => {
      this.params.offset = 0;
      this.getMarkers();
    }, this)
    this.controller.on('map.reset', (e) => {
      this.resetMap();
    })
    this.render();
  },
  setInactive: function () {
    this.active = false;
    this.controller.off('filter.config.changed', null, this);
    this.controller.off('filter.markers.loaded', null, this);
    this.controller.off('map.reset', null, this);
    this.removeMarkers();
    if (this.Map) {
      this.Map.remove();
      delete this.Map;
      this.Map = null;
    }
    this.$el.empty();
  },
  resetMap: function () {
    this.removeMarkers();
    if (this.Circle) this.Circle.remove();
    if (this.Map) {
      this.Map.fitBounds(this.bounds);
      this.getMarkers();
    }
  },
  getMarkers: function () {
    this.controller.getMarkers(this.params);
  },
  removeMarkers: function () {
    if (this.markers) {
      this.markers.clearLayers();
    }
  },
  addUserMarker: function (coords) {

    if (this.userMarker) return;
    let marker = L.marker([coords.lat, coords.lng], {
      icon: Icon
    }).addTo(this.Map);
    this.userMarker = marker;
  },
  setMarkers: function (markers) {
    this.removeMarkers();
    let Icon = L.icon({
      iconUrl: window.tpdata.assets + '/marker-icon-custom-neu.png',
      shadowUrl: window.tpdata.assets + '/marker-shadow.png',
    });

    if (this.controller.geomode === 'zip' && this.controller.geoconfig) {
      if (this.Circle) this.Circle.remove();
      this.Circle = L.circle([this.controller.geoconfig.lat, this.controller.geoconfig.lng], this.controller.ZipRadius.radius * 1000, {
        opacity: 0.1,
        fillColor: '#1F2B5B',
        fill: '#1F2B5B',
      }).addTo(this.Map);
    }


    var cluster = L.markerClusterGroup({
      showCoverageOnHover: false,
      maxClusterRadius: 20,
      iconCreateFunction: function (cluster) {
        var childCount = cluster.getChildCount();
        var size = 40;
        var c = ' marker-cluster-';
        if (childCount < 10) {
          c += 'small';
          size = 30;
        } else if (childCount < 30) {
          c += 'medium';
          size = 40;
        } else {
          c += 'large';
          size = 50;
        }

        return new L.DivIcon({
          html: '<div><span>' + childCount + '</span></div>',
          className: 'marker-cluster' + c,
          iconSize: new L.Point(size, size)
        });

      }
    });
    _.map(markers, (m) => {
      if (m.pin && m.pin.showonmap === true) {
        let iconurl = window.tpdata.assets + '/marker-icon-custom-klima.png';
        if (m.pin.hasOwnProperty('finished') && m.pin.finished === true){
          iconurl = window.tpdata.assets + '/marker-icon-custom-neu.png';
        }
        let subtitle = ''
        if (m.oterms && m.oterms.length === 1) {
          let term = m.oterms[0];
          if (term && term.mapicon && term.mapicon !== '') {
            iconurl = term.mapicon;
          }
          switch (term.term_id) {
            case 88:
              iconurl = window.tpdata.assets + '/marker-icon-custom-zukunft.png';
              break;
            case 105:
              iconurl = window.tpdata.assets + '/marker-icon-custom-kommunikation.png';
              break;
            case 91:
              iconurl = window.tpdata.assets + '/marker-icon-custom-klima.png';
              break;
          }


          if (term && term.name) {
            subtitle = term.name;
          }
        }

        let Icon = L.icon({
          iconUrl: iconurl,
          iconSize: [25, 33],
          iconAnchor: [12, 33],
          popupAnchor: [0, -33],
          shadowUrl: window.tpdata.assets + '/marker-shadow.png',
        });

        let marker = L.marker([m.pin.location.lat, m.pin.location.lng], {
          icon: Icon
        });
        let view = new EntryView({model: new Backbone.Model(m), ModalHandler: this.ModalHandler});
        marker.view = view;
        view.marker = marker;

        // var popup = L.popup()
        //   .setContent(markerPopup(m))
        //
        // marker.bindPopup(popup);
        marker.on('click', function () {
          // this.openPopup();
          marker.view.openModal()
        });

        marker.on('mouseover', function (e) {
          // this.openPopup();
        });
        marker.on('mouseout', function (e) {
          // this.closePopup();
        });
        cluster.addLayer(marker);
      }
    })
    this.markers = cluster;
    this.Map.addLayer(cluster);
    if (this.controller.geomode === 'zip' && this.controller.geoconfig) {
      if (this.Circle) {
        this.Map.fitBounds(this.Circle.getBounds());
      }
    }
    setTimeout(() => {
      this.Map.invalidateSize();
    }, 200)
  }
});