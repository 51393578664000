import Map from './stages/Map'
import Tiles from './stages/Tiles'

export default Backbone.View.extend({
  mode: 'map',
  initialize: function (options) {
    this.controller = options.controller;
    this.Map = new Map(options);
    this.Tiles = new Tiles(options);
    this.controller.once('filter.data.loaded', (e) => {
      this.render();
    })
  },
  switchMode: function (mode) {
    this.activeStage.unbind();
    this.activeStage.setInactive();
    this.mode = mode;
    this.render();
  },
  render: function () {
    switch (this.mode) {
      case "map":
        this.Map.setActive(true);
        this.activeStage = this.Map;
        break;
      case "tiles":
        this.Tiles.setActive(true);
        this.activeStage = this.Tiles;
        break;
    }
  }
})