var boxTpl = require('../../templates/zip-radius.hbs');
var formTpl = require('../../templates/zip-radius-form.hbs');
var $ = require('jquery');
export default Backbone.View.extend({
  name: 'Postleitzahl / Umkreis',
  selected: [],
  zipcode: '',
  radius: '50',
  initialize: function (options) {
    this.controller = options.controller;
    this.$list = options.$wrap;
    this.render();
    this.controller.on('filter.data.loaded', (e) => {
      this.renderItems();
    })
  },
  events: {
    'keyup [name=zipcode]': 'updateZipcode',
    'change [name=radius]': 'updateRadius',
    'click .fi--header': 'toggleBox',
    'click button': 'requestGeocode',
    'click [data-user-location]': 'getUserLocation'
  },
  reset: function () {
    this.selected = [];
    this.radius = 50;
    this.zipcode = '';
    this.$('[name=zipcode]').val('');
  },
  toggleBox: function () {
    this.controller.activateGeoMode('zip');
  },
  getUserLocation: async function () {
    let that = this;
    navigator.geolocation.getCurrentPosition(async function (position) {
      let address = await jQuery.ajax('https://nominatim.openstreetmap.org/reverse', {
        data: {
          lat: position.coords.latitude,
          lon: position.coords.longitude,
          format: 'json'
        }
      })
      that.controller.trigger('user.location.marker', {
        lat: position.coords.latitude,
        lng: position.coords.longitude
      });

      that.zipcode = address.address.postcode;
      that.requestGeocode();
    });
  },
  requestGeocode: async function () {
    if (this.zipcode.length < 5) {
      alert('Bitte geben Sie eine fünstellige deutsche Postleitzahl an')
    }
    this.trigger('zipcode.changed');
  },
  updateZipcode: function () {
    this.zipcode = this.$('[name=zipcode]').val();
  },
  updateRadius: function () {
    this.radius = this.$('[name=radius]').val();
  },
  renderItems: function () {
    this.$('.fi--entries').empty().append(formTpl({zipcode: this.zipcode, radius: this.radius}));
    this.$('option[value="' + this.radius + '"]').prop('selected', true);
    setTimeout(() => {
      if ("geolocation" in navigator) {
      } else {
        this.$el.find('.near-to-me').hide();
      }
    }, 500)
  },
  render: function () {
    this.$el.empty().append(boxTpl({name: this.name}));
    this.$list.append(this.$el);
  }
});