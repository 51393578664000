import BookmarkManager from "./bookmark/BookmarkManager";

let tileWrap = require('../templates/stages/tiles-wrap.hbs');
let tileItem = require('../templates/stages/project-item-wrap.hbs');
import ModalHandler from "./modals/ModalHandler";
import EntryView from "./modals/EntryView";

export default Backbone.View.extend({
  initialize: function (options) {

    this.inline = this.$el.data('projectsInline');
    if (this.inline){
      let els = this.$('[data-ref-id]');
      let ids = _.map(els,(el) => {
        return el.dataset.refId;
      })
      this.$el.data('refs',ids.join(','));
    }


    this.items = this.$el.data('refs').split(',') || '';
    this.BookmarkManager = new BookmarkManager({controller: this, $wrap: this.$('.bk-holder')});
    this.controller = options.controller;
    this.controller.BookmarkManager = this.BookmarkManager;
    this.$tilesWrap = this.$('[data-tiles-wrap]');
    this.views = this.setupViews();
    this.ModalHandler = new ModalHandler({controller: this.controller});
  },
  events: {
    'click [data-create-multi-pdf]': 'triggerPdf',
    'click [data-bookmark-remove]': 'removeItem'
  },
  setupViews: async function () {
    let fdata = await jQuery.ajax(window.tpdata.endpoints.reflist, {
      method: 'get',
      data: {refs: this.items}
    });
    let views = _.map(fdata, (e) => {
      e.inline = this.inline;
      return new EntryView({model: new Backbone.Model(e), ModalHandler: this.ModalHandler})
    });
    this.renderEntries(views)
  },
  renderEntries: function (entries) {
    this.$tilesWrap.empty();
    _.each(entries, (e) => {
      this.$tilesWrap.append(e.render());
    })
  },
  triggerPdf: function () {
    let url = '/?createpdf&multipdf=' + this.items.join(',');
    window.open(url, '_blank');
  },
});