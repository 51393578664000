var Handlebars = require("../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"flex-container align-middle\">\n    <div class=\"bk--image\">\n        <img src=\""
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"images") : depth0)) != null ? lookupProperty(stack1,"0") : stack1)) != null ? lookupProperty(stack1,"small") : stack1), depth0))
    + "\" width=\"30\" height=\"30\">\n    </div>\n    <div class=\"bk--title\">\n        "
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"formattedTitle") : depth0), depth0))
    + "\n    </div>\n    <div class=\"bk--item-actions flex-end\">\n        <div data-ref-id=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"ID") : depth0), depth0))
    + "\" data-bookmark-createpdf class=\"bk--pdf-icon\">\n            <a style=\"display: block; height: 16px;\" data-tippy-content=\"PDF Datei herunterladen\"\n               href=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"pdflink") : depth0), depth0))
    + "\"\n               target=\"_blank\"></a></div>\n        <div data-tippy-content=\"von der Merkliste entfernen\" data-ref-id=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"ID") : depth0), depth0))
    + "\" data-bookmark-remove\n             class=\"bk--remove\">\n        </div>\n    </div>\n</div>";
},"useData":true});