var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "selected";
},"3":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <div class=\"ref-item-tags\" style=\"display: none;\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? lookupProperty(depth0,"term") : depth0)) != null ? lookupProperty(stack1,"tags") : stack1),{"name":"each","hash":{},"fn":container.program(4, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":10,"column":12},"end":{"line":15,"column":21}}})) != null ? stack1 : "")
    + "        </div>\n";
},"4":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <div class=\"ref-tag-item "
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"tagSelected") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":11,"column":41},"end":{"line":11,"column":84}}})) != null ? stack1 : "")
    + "\"\n                     data-term-parent=\""
    + alias2(alias1(((stack1 = (depths[1] != null ? lookupProperty(depths[1],"term") : depths[1])) != null ? lookupProperty(stack1,"term_id") : stack1), depth0))
    + "\" data-term-id=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"term_id") : depth0), depth0))
    + "\">\n                    "
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"name") : depth0), depth0))
    + "\n                </div>\n";
},"5":function(container,depth0,helpers,partials,data) {
    return "tag-selected";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.lambda, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"ref-topic-wrap "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"selected") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":27},"end":{"line":1,"column":63}}})) != null ? stack1 : "")
    + "\">\n    <div class=\"ref-checkbox-item "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"term") : depth0)) != null ? lookupProperty(stack1,"selected") : stack1),{"name":"if","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":2,"column":34},"end":{"line":2,"column":75}}})) != null ? stack1 : "")
    + "\">\n        <input id=\"t-"
    + alias3(alias2(((stack1 = (depth0 != null ? lookupProperty(depth0,"term") : depth0)) != null ? lookupProperty(stack1,"term_id") : stack1), depth0))
    + "\" type=\"checkbox\" name=\"topic\" "
    + ((stack1 = alias2(((stack1 = (depth0 != null ? lookupProperty(depth0,"term") : depth0)) != null ? lookupProperty(stack1,"checked") : stack1), depth0)) != null ? stack1 : "")
    + "\"\n        value=\""
    + alias3(alias2(((stack1 = (depth0 != null ? lookupProperty(depth0,"term") : depth0)) != null ? lookupProperty(stack1,"term_id") : stack1), depth0))
    + "\">\n        <label for=\"t-"
    + alias3(alias2(((stack1 = (depth0 != null ? lookupProperty(depth0,"term") : depth0)) != null ? lookupProperty(stack1,"term_id") : stack1), depth0))
    + "\">"
    + alias3(alias2(((stack1 = (depth0 != null ? lookupProperty(depth0,"term") : depth0)) != null ? lookupProperty(stack1,"name") : stack1), depth0))
    + "</label>\n        <span class=\"fi--entry-count\">"
    + alias3(alias2((depth0 != null ? lookupProperty(depth0,"prefix") : depth0), depth0))
    + alias3(alias2((depth0 != null ? lookupProperty(depth0,"count") : depth0), depth0))
    + "</span>\n    </div>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"term") : depth0)) != null ? lookupProperty(stack1,"tags") : stack1),{"name":"if","hash":{},"fn":container.program(3, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":8,"column":4},"end":{"line":17,"column":11}}})) != null ? stack1 : "")
    + "</div>";
},"useData":true,"useDepths":true});