var Handlebars = require("../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <span class=\"ref-distance\">"
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"distancehuman") : depth0), depth0))
    + " km</span>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <img loading=\"lazy\" src=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"tpdata") : depth0)) != null ? lookupProperty(stack1,"images") : stack1), depth0))
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"featured") : depth0), depth0))
    + "\"\n             alt=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"formattedTitle") : depth0), depth0))
    + "\">\n";
},"5":function(container,depth0,helpers,partials,data) {
    return "        <div class=\"img-placeholder\"></div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"hovercard-container\">\n  <div class=\"flex-container hopper\">\n    <div class=\"hover-tile\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"distance") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":4,"column":6},"end":{"line":6,"column":13}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"featured") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.program(5, data, 0),"data":data,"loc":{"start":{"line":8,"column":6},"end":{"line":13,"column":13}}})) != null ? stack1 : "")
    + "      <div class=\"hover-overlay\">\n        <div class=\"hover-overlay--header align-middle\">\n          <h4 class=\"hl hl--six\">"
    + ((stack1 = container.lambda((depth0 != null ? lookupProperty(depth0,"formattedTitle") : depth0), depth0)) != null ? stack1 : "")
    + "</h4>\n        </div>\n      </div>\n\n    </div>\n  </div>\n</div>";
},"useData":true});