var Handlebars = require("../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                <div class=\"bk--entry\">\n"
    + ((stack1 = container.invokePartial(require("./bookmark-item.hbs"),depth0,{"name":"bookmark-item","data":data,"indent":"                    ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "                </div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"bk--header\">\n    <h3 class=\"hl hl--four\">"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"options") : depth0)) != null ? lookupProperty(stack1,"boxtitle") : stack1), depth0))
    + "</h3>\n    <button class=\"close-button\" data-dialog-close>x</button>\n</div>\n<div class=\"bk--content\">\n    <div class=\"scroll-container\">\n        <p>"
    + ((stack1 = alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"options") : depth0)) != null ? lookupProperty(stack1,"boxtext") : stack1), depth0)) != null ? stack1 : "")
    + "</p>\n        <div class=\"bk-sortable\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"items") : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":9,"column":12},"end":{"line":13,"column":21}}})) != null ? stack1 : "")
    + "        </div>\n    </div>\n    <div class=\"cta cta--one\">\n        <span data-create-multi-pdf class=\"text-link bk-icon-print\">"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"options") : depth0)) != null ? lookupProperty(stack1,"boxprint") : stack1), depth0))
    + "</span>\n        <span data-share-list class=\"text-link bk-icon-share\">"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"options") : depth0)) != null ? lookupProperty(stack1,"boxshare") : stack1), depth0))
    + "</span>\n        <div class=\"bk-share-link-wrap\" style=\"display: none;\">\n            <input id=\"shorturl\" readonly type=\"text\" value=\"\">\n            <div class=\"bk-clipboard-wrap\" data-tippy-content=\"in die Zwischenablage kopieren\">\n                <span data-clipboard-target=\"#shorturl\" class=\"bk-copy bk-icon-clipboard\"></span>\n            </div>\n        </div>\n    </div>\n</div>\n";
},"usePartial":true,"useData":true});