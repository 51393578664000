import BookmarkModel from "./BookmarkModel";
import store from 'store';
export default Backbone.Collection.extend({
  model: BookmarkModel,
  sync: function(){
    let props = _.map(this.models, (m) => {
      return m.toJSON();
    });
    store.set('bookmarks', props)
  },
  getItems: function(){
    return _.map(this.models, (m) => {
      return m.toJSON();
    });
  }
});